export const EN = {
    LOADING: 'Loading',
    DASHBOARD: 'Dashboard',
    CATEGORIES: 'Categories',
    TAGS: 'Tags',
    TRENDS: 'Trends',
    BANNERS: 'Banners',
    WIDGETS: 'Widgets',
    PAGES: 'Pages',
    USERS: 'Users',
    DOCUMENTS: 'Documents',
    ARTICLE: 'Article',
    EPISODE: 'Episode',
    GALLERY: 'Gallery',
    INFOGRAPHIC: 'Infographic',
    AUTHORS: 'Authors',
    GROUPS: 'Groups',
    PERMISSIONS: 'Permissions',
    PROFILE: 'Profile',
    LOGOUT: 'Logout',
    NO_EVENTS: 'no events for you',
    WELCOME_MESSAGE: 'Welcome to admin panel',
    DASHBOARD_WELCOME: 'Welcome to admin panel',
    ARTICLE_LIST: 'Articles list',
    EPISODE_LIST: 'Episodes list',
    GALLERY_LIST: 'Gallery list',
    INFOGRAPHIC_LIST: 'Infographics list',
    LOADING_DOCUMENTS: 'Documents loading',
    EDIT: 'edit document',
    VIEW: 'preview document',
    DELETE: 'delete document',
    CROSSLINKS: 'crosslinks',
    SHOW_ALL_TAGS: 'show all tags',
    HIDE_ALL_TAGS: 'hide all tags',
    IMAGE: 'image',
    COVER: 'cover',
    VIDEO: 'video',
    EXTERNALVIDEO: 'external video',
    CODE: 'code',
    CATEGORY: "Category",
    AUTHOR: 'Author',
    PUBLISHED: 'published',
    UNPUBLISHED: 'unpublished',
    PREPUBLISHED: 'prepublished',
    CREATE: 'Create',
    TITLE_OR_URL: 'title or url',
    SELECT_STATUS: 'select status',
    SELECT_TAGS: 'select tags',
    SELECT_CATEGORY: 'select category',
    DATE_FROM: 'date from',
    DATE_TO: 'date till',
    ALL: 'all',
    EMPTY_DOCUMENTS: 'No documents',
    CLEAR_FILTER: 'clear_filter',
    CREATED: 'Created',
    UPDATED: 'Updated',
    AT: 'at',
    SURE_DELETE: 'You are sure that you want to delete this document?',
    CLOSE: 'Close',
    NOT_SET: '[not set]',
    ARTICLE_ADD: 'Add article',
    UPLOAD_TEXT: 'Click or drag file to this area to upload',
    COVER_LABEL: 'Cover',
    UPLOAD_NEW: 'New image',
    UPLOAD_SEL: 'Select image',


    PODCASTS_LIST: 'Podcasts',
    PODCASTS: 'Podcasts',
    PODCASTS_EDIT: 'Podcasts',
    PCATEGORIES_LIST: 'Category',
    PODCAST_CATEGORIES: 'Categories',
    PCATEGORIES_EDIT: 'Categories',
    PCATEGORIES: 'Category',
    TYPE_PCATEGORIES: 'Categories',


    TYPE_TAGS: "Tags",

    YANDEX_DZEN_TITLE: 'Заголовок для DZEN',

    NEWS: 'News',
    ARTICLES: 'Articles',
    TITLE: 'Title',
    UPDATE_DOCUMENT: 'Save',
    DOC_STATUS: 'Status',
    CREATE_BY: 'Created by',
    UPDATED_BY: 'Updated by',
    HISTORY: 'History',
    SUMMARY: 'Lead',
    DELETE_MEDIA: 'Remove cover',
    TEXT: 'Text',
    SLUG: 'Slug',
    PUBLISHED_AT: 'Published at',
    COORDS: 'Coordinates',
    COORDS_HELP: 'Введите радианы места',
    HIDE_MAP: 'Hide map',
    SHOW_MAP: 'Show map',
    GENERATE: 'Generate',
    PUBLISH_IN: 'Publish in',
    SUB_CATEGORY: 'Subcategories',
    VIEW_FIELD: 'View',
    SEO_TITLE: 'Seo title',
    SEO_DESCRIPTION: 'Seo description',
    REDIRECT_TO: 'Redirect',
    SEO_AND_VIEW: 'Seo and view',
    published: 'published',
    DOCUMENT_HISTORY_EVENT: 'Document history',
    RECOVER: 'Recover',
    TITLE_ERROR_FILL: 'Fill document title',
    SUMMARY_ERROR_FILL: 'Fill document summary',
    SLUG_PLACEHOLDER: 'Fill document placeholder',
    SLUG_ERROR: 'Fill document slug',
    SELECT_CATEGORIES: 'Select document category',
    SELECT_AUTHORS_ERROR: 'Fill document authors',
    AUTHORS_PLACEHOLDER: 'Select document authors',
    TITLE_PLACEHOLDER: 'Fill title',


    IMAGE_FIELD: 'Has image',
    VIDEO_FIELD: 'Has video',
    MAINPAGE_FIELD: 'Publish at main',

    DRAFT: 'Draft',
    PUBLISH: "Published",
    READY: "Ready",
    ARCHIVE: "Archive",
    PREPUBLISHED_DASHBOARD: 'Prepublished',

    WORKFLOW: 'Workflow',

    MEDIAGALLERY_LOADING_ERROR_MESSAGE: 'Failed to load media archive',
    MEDIAGALLERY_LOADING_ERROR_DESCRIPTION: 'Server response incorrect code',
    MEDIA_GALLERY_LIST: 'Media archive',

    MEDIAGALLERY_RELOAD: 'Reload',

    MEDIGALLERY_UPLOAD_TITLE: 'Click or drag file',

    MEDIAGALLERY_UPLOAD_TITLE: 'Media file',

    DATABASES: 'Database',

    INSTRUMENTS: 'Instruments',
    PUSH: 'Push notifications',
    REDIRECTS: 'Redirects',

    TAG_LIST: 'Tags',

    CREATE_BTN: 'Create',
    DOCUMENT_CREATED: 'Document has been created',
    VISIBLE: 'Visible',
    SUMMARY_PLACEHOLDER: 'Fill summary',
    TAG_EDIT: 'Edit tag',

    CATEGORY_LIST: 'Categories',

    CREATED_AT: 'Created at',
    UPDATED_AT: 'Updated at',
    ACTIONS: 'Actions',
    CATEGORY_EDIT: 'Edit category',
    SELECT_TRENDS: 'Select trends',
    PUBLISH_ACTION: 'Publish',
    PUBLISH_POPCONFIRM: 'Publish document?',
    SEARCH: 'Search',
    CLEAR: 'Clear filter',
    SEARCH_PLACEHOLDER: 'Fill search query',
    MEDIAGALLERY_FILL_SEARCH: 'Fill title, copyright',
    LOADING_DOCUMENT: 'Loading',

    /*Genres*/
    GENRES: 'Genres',
    GENRES_LIST: 'Genres',
    GENRES_EDIT: 'Edit genre',

    /*Authors*/
    AUTHORS_LIST: 'Authors',
    AUTHOR_LIST: 'Authors',
    AUTHOR_EDIT: 'Edit author',

    AUTHOR_NAME: 'Name',
    AUTHOR_DESCRIPTION: 'Description',
    AUTHOR_COVER: 'Photo',

    MOVE_TYPE: 'Move type',
    NO_HISTORY: 'Empty history',
    SEARCH_MEDIA_PLACEHOLDER: 'Search query',
    NO_MEDIA:'Empty response',

    MEDIA_TITLE: 'Title',
    FILL_MEDIA_TITLE: "Fill media title",

    MEDIA_SOURCE: 'Source',
    MEDIA_COPYRIGHT: 'Copyright',
    MEDIA_COPYRIGHT_URL: 'Copyright url',
    MEDIA_DELETE: 'Delete',
    MEDIA_UPLOAD: 'Upload',

    MEDIAGALLERY_TYPE_COVER: 'Cover',
    MEDIAGALLERY_TYPE_IMAGE: 'Image',
    MEDIAGALLERY_TYPE_VIDEO: 'Video',
    MEDIAGALLERY_DELETE_REJECT: 'Cancel',
    MEDIAGALLERY_DELETE_OK: 'Delete',
    SELECT: 'Select',
    'MEDIA.EDIT': 'Edit',
    'MEDIA.DELETE': 'Delete',
    UNPUBLISH_ACTION: 'Unpublish',
    UNPUBLISH_POPCONFIRM: 'Unpublish document?',
    DOCUMENT_UPDATED: 'Document has been updated',
    SELECT_WORKFLOW: 'Select workflow',
    SELECT_TREND: 'Select trend',
    BOOKS: 'Books',
    BOOK_LIST:'Books',
    TECH_INFO: 'Теch info',
    ADMIN_WIDGET_CODE: 'Widget code',
    ADMIN_TITLE: 'Admin title',
    DOC_TYPES: 'Document types',
    DOCS_SIZE: 'Documents count',
    MAX_SIZE: 'Max size',
    SORT: 'Sort',
    WIDGETS_LIST: 'Widgets',

    'USERS_LIST': 'Users',
    FRENCH_QUOTES: "quotes",
    MIDDLE_DASH: "Middle dash",
    LONG_DASH: "Long dash",
    EDITOR_INSERT_MEDIA: "Insert media",
    OPTIONS_AGE_RESTRICTIONS: "Age restrictions",
    OPTIONS_DATE_PRINT: "Print date",
    OPTIONS_PAGES: "Pages count",

    GENRES_PLACEHOLDER: 'Genres',
    SELECTION_LIST: 'Selections',
    SELECTIONS: 'Selections',
    BOOK_EDIT: 'Book edit',
    SHOW_HISTORY: 'History',
    DOCUMENT_UPDATE_SUCCESS: 'Document has been updated',
    INSERT_IMAGE: 'Images',
    INSERT_VIDEO: 'Videos',
    INSERT_EXTERNALVIDEO: 'External video',
    INSERT_CODE: 'External code',
    UPLOAD: 'Upload',
    SELECT_MEDIA: 'Media archive',
    INSERT_PLUGIN: 'Insert media',
    ADD: "add",
    SELECTION_PLACEHOLDER: 'Choose selection',

    BANNER_LIST: 'Banners',
    WIDGET_VIEW_TITLE: 'Widget view',
    PUBLISHER: 'Publisher',
    DELETE_PDF: 'delete',
    WTYPE_ALL: "All",
    WTYPE_MAIN_PAGE: "Main page",
    WIDGET_ADD_TITLE: 'Create new widget',
    WIDGET_TITLE: "Title",
    WIDGET_TITLE_PLACEHOLDER: "Widget title for users",
    ADMIN_WIDGET_CODE_PLACEHOLDER: "Code",
    ADMIN_WIDGET_TITLE: "Admin title",
    TYPE_BOOK: "Book",
    TYPE_AUTHOR: "Author",
    TYPE_SELECTION: "Selection",
    TYPE_GENRE: "Genre",
    TYPE_BANNER: "Banner",
    WIDGET_TYPE: "Widget position",
    WIDGET_TYPE_MAIN: "Mainpage",
    WIDGET_VIEW: "View",
    WIDGETS_EDIT: "Edit widget"
};

export default EN;
