import React from 'react';
import {Layout, Button, Avatar} from 'antd';
import asyncComponent from './../core/components/async';
import Config from './../config';
// import { AppConsumer } from '../context/app';
import Store from "../core/library/store";


const getUserPermissions = (p) => {
    if (p && p.info) {
        if (p.info.permissions && Array.isArray(p.info.permissions)) {
            return p.info.permissions;
        }
    }
    return false;
}

const isPhotoUser = (p) => {
    const perm = getUserPermissions(p);
    return perm && Array.isArray(perm) && perm.indexOf(`ONLY_PHOTO`) + 1 > 0;
}


const Menu = asyncComponent(() => {
    return import(`./../core/components/menu.js`);
});

// const Header = asyncComponent(() => {
//     return import(`./../core/components/header/index.js`);
// });

const Router = asyncComponent(() => {
    return import('./../core/router/index.js');
});


export class DefaultLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: Store.get('sidebar_collapsed') === '1',
            loading: false,
            languages: [],
        };
    }

    componentDidMount() {
        // documentsElLoad('Languages', 1, {}, '-publishedAt', 100).then(data => {
        //     window.AL_LIST = window.AL_LIST || {};
        //     window.AL_LIST['Languages'] = data.list || [];
        //     this.setState({languages: data.list || [], loading: false})
        // });
    }

    // onCollapse = collapsed => {
    //     Store.set('sidebar_collapsed', collapsed ? '1' : '0')
    //     this.setState({collapsed});
    // };

    render() {
        const isPhoto = isPhotoUser(this.props.user || {});
        const menu = isPhoto ? Config.PHOTO_MENU : Config.MENU;
        console.log(this.props.user.info);
        return (
            <Layout style={{minHeight: '100vh'}}>
                <Layout.Header className={`main--header`}>
                    <div className={"main--header__nav"}>
                        <a href={"/"}><div className="logo" /></a>
                        <Menu items={menu || []} mode={`horizontal`}/>
                    </div>
                    <div className={"main--header__user"}>
                        <div className="user--info">
                            <Avatar size={34} />
                            {this.props.user.info.lastname} {this.props.user.info.firstname}
                        </div>
                        <Button icon={'logout'} onClick={() => {
                            Store.remove('oauth_refresh');
                            Store.remove('oauth_access');
                            window.location.href = 'https://auth.rt.com/logout'
                        }}/>
                    </div>
                </Layout.Header>
                <Layout.Content style={{ padding: '0 50px' }}>
                    <Layout style={{ padding: '24px 0', maxWidth: 1920, width: '100%', margin: '0 auto'}}>
                        <Router {...this.props} isPhotoUser={isPhoto}/>
                    </Layout>
                </Layout.Content>
            </Layout>
        );
    }
}

export default DefaultLayout;
